export const privacy = `
onnuon 개인정보처리방침
주식회사 onnuon(이하 '회사')은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침을 수립・공개합니다.
제1조 (개인정보의 처리 목적)
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
회원가입 및 관리
회원 가입의사 확인
회원제 서비스 제공에 따른 본인 식별・인증
회원자격 유지・관리
서비스 부정이용 방지
각종 고지・통지
고충처리 목적
분쟁 조정을 위한 기록 보존
회원탈퇴 의사의 확인
서비스 제공
특수 식단(할랄, 코셔, 비건 등) 필터링 서비스 제공
식재료 정보 제공
전자메뉴 서비스 제공
알레르기 정보 제공
다국어 메뉴 정보 제공
서비스 개선 및 통계 분석
서비스 이용 통계 분석
서비스 개선을 위한 이용패턴 분석
맞춤형 서비스 제공을 위한 데이터 분석
신규 서비스 개발을 위한 통계 분석
제2조 (개인정보의 처리 및 보유기간)
① 회사는 법령에 따른 개인정보 보유・이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유・이용기간 내에서 개인정보를 처리・보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
회원가입 정보
보유기간: 회원탈퇴 시까지
보유근거: 회원 서비스 제공을 위한 필요성
서비스 이용 기록
보유기간: 6개월
보유근거: 서비스 이용 통계 및 부정 이용 방지
접속 로그
보유기간: 3개월
보유근거: 통신비밀보호법
설정 정보(식단 선호도, 알레르기 정보 등)
보유기간: 회원탈퇴 시까지
보유근거: 맞춤형 서비스 제공
법령에 따른 보존 필요 정보
보유기간: 관련 법령에서 정한 기간
보유근거: 전자상거래 등에서의 소비자 보호에 관한 법률 등
제3조 (처리하는 개인정보의 항목)
회사는 다음의 개인정보 항목을 처리하고 있습니다.
필수항목
이메일 주소: 계정 식별 및 고지사항 전달
비밀번호: 계정 보안
닉네임: 서비스 이용 식별
선택항목
식단 선호도(할랄, 코셔, 비건 등): 맞춤형 메뉴 필터링
알레르기 정보: 알레르기 유발 식재료 필터링
언어 설정: 다국어 서비스 제공
서비스 이용 설정: 사용자 맞춤 환경 제공
자동 수집 항목
IP 주소: 서비스 보안 및 부정 이용 방지
쿠키: 자동 로그인 및 사용자 설정 유지
서비스 이용 기록: 서비스 개선 및 통계
접속 로그: 보안 관리
접속 기기 정보: 서비스 최적화
제4조 (개인정보의 제3자 제공)
① 회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
② 회사는 현재 이용자의 개인정보를 제3자에게 제공하지 않습니다.
③ 향후 개인정보를 제3자에게 제공하는 경우에는 사전에 다음의 사항을 알리고 동의를 받습니다.
제3자의 명칭
제공받는 자의 개인정보 이용 목적
제공하는 개인정보의 항목
개인정보를 제공받는 자의 개인정보 보유 및 이용기간
동의를 거부할 수 있다는 사실 및 동의 거부에 따른 불이익의 내용
제5조 (개인정보의 파기절차 및 방법)
① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
파기절차
회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 관련 법령에 따라 일정기간 저장 후 파기됩니다.
개인정보는 법령에 따른 보존기간을 준수하여 파기됩니다.
파기방법
전자적 파일 형태로 기록・저장된 개인정보는 기록을 재생할 수 없도록 파기합니다.
종이 문서에 기록・저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
제6조 (정보주체와 법정대리인의 권리・의무 및 행사방법)
① 정보주체는 회사에 대해 언제든지 개인정보 열람・정정・삭제・처리정지 요구 등의 권리를 행사할 수 있습니다.
② 제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 "개인정보 처리 방법에 관한 고시(제2020-7호)" 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
제7조 (개인정보의 안전성 확보조치)
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
관리적 조치
내부관리계획 수립・시행
정기적 직원 교육
개인정보 취급자 지정 최소화
개인정보취급자에 대한 정기 교육 실시
기술적 조치
개인정보처리시스템 등의 접근권한 관리
접근통제시스템 설치
고유식별정보 등의 암호화
보안프로그램 설치 및 주기적 갱신・점검
접속기록의 보관 및 위・변조 방지를 위한 조치
네트워크 보안 강화
백신 프로그램 설치・운영
물리적 조치
전산실, 자료보관실 등의 접근통제
비인가자에 대한 출입 통제
문서보안을 위한 잠금장치 설치
제8조 (개인정보 자동 수집 장치의 설치・운영 및 거부에 관한 사항)
① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
쿠키의 사용목적
이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
쿠키의 설치・운영 및 거부
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.
웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
제9조 (개인정보 보호책임자)
① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
▶ 개인정보 보호책임자 
성명: 박성철
직책: 책임자
연락처: 02-1588-8932, info@onnuon.com
② 정보주체께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
제10조 (개인정보 열람청구)
① 정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
▶ 개인정보 열람청구 접수・처리 부서
부서명: 고객지원팀
담당자: 개인정보 보호담당자
연락처: 02-1588-8932, privacy@onnuon.com
② 정보주체께서는 제1항의 열람청구 접수・처리부서 이외에, 개인정보보호위원회의 '개인정보보호 포털' 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실 수 있습니다.
제11조 (권익침해 구제방법)
정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
대검찰청 : (국번없이) 1301 (www.spo.go.kr)
경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
제12조 (개인정보의 추가적인 이용・제공 기준)
회사는 「개인정보 보호법」 제15조제3항 및 제17조제4항에 따라 개인정보보호법 시행령 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용・제공할 수 있습니다. 이에 따라 회사가 정보주체의 동의 없이 추가적인 이용・제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
개인정보를 추가적으로 이용・제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부
개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용・제공에 대한 예측 가능성이 있는지 여부
개인정보의 추가적인 이용・제공이 정보주체의 이익을 부당하게 침해하는지 여부
가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
제13조 (가명정보의 처리)
회사는 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 개인정보를 가명처리할 수 있습니다. 회사는 가명정보를 처리하는 경우에는 「개인정보 보호법」 제28조의4에 따라 다음과 같은 필요한 기술적・관리적・물리적 안전조치를 취합니다.
법에서 정한 사항을 준수하여 가명정보를 처리합니다.
가명정보의 안전한 처리를 위하여 필요한 조치를 취합니다.
가명정보의 처리 목적, 제3자 제공 시 제공받는 자 등 처리에 관한 내용을 관리하기 위하여 대장을 작성합니다.
제14조 (개인정보 처리방침 변경)
① 이 개인정보처리방침은 2025년 1월 21일부터 적용됩니다.
② 회사는 본 개인정보처리방침을 변경하는 경우 웹사이트 또는 앱 화면을 통하여 공지할 것이며, 변경된 개인정보처리방침은 공지한 날로부터 7일 후부터 효력이 발생합니다.
③ 이전의 개인정보처리방침은 아래에서 확인하실 수 있습니다.
공고일자: 2025년 1월 14일
시행일자: 2025년 1월 21일
`;
