import { terms } from 'lib/terms';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

type Props = {
  setTermsModal: Dispatch<SetStateAction<boolean>>;
};

export default function TermsModal({ setTermsModal }: Props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[9999]'>
      <div className='bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto'>
        <div className='p-6'>
          {/* 헤더 영역 */}
          <div className='flex justify-between items-center mb-6'>
            <h2 className=' font-bold'>이용약관</h2>
            <button onClick={() => setTermsModal(false)} className='text-gray-500 hover:text-gray-700'>
              <XCircleIcon className='w-6 h-6' />
            </button>
          </div>

          {/* 내용 영역 */}
          <div className='prose max-w-none p-6'>
            <p className='text-[14px] font-NEXONFootballGothicBA1 whitespace-pre-wrap'>{terms}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
