import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { BannerDetailResponse, fetchBannerDetail } from 'api/admin/banner';
import { getCookie } from 'api/cookie';
import Toggle from 'components/common/Toggle';
import CustomButton from 'components/style/Button';
import CustomInput from 'components/style/Input';
import useFile from 'hooks/useMulter';
import { errorHandler } from 'lib/error';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useModalStore from 'store/useModalStore';
import { z } from 'zod';

type Props = {
  bannerId: string | null;
  setBannerId: Dispatch<SetStateAction<string | null>>;
};

interface BannerForm {
  image: string;
  link?: string;
  isActivated: boolean;
}

const createBannerSchema = (t: (key: string) => string) =>
  z.object({
    image: z.string().min(1, { message: t('validation.imageRequired') }),
    link: z.string().optional(),
    isActivated: z.boolean(),
  });

export default function BannerDetailModal({ bannerId, setBannerId }: Props) {
  const bgRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const bannerSchema = createBannerSchema(t);
  const { setModals, setOneBtnModal } = useModalStore();
  const { fileMutate, saveFile, imageValidation } = useFile();
  const [preview, setPreview] = useState<string | null>(null);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<z.infer<typeof bannerSchema>>({
    resolver: zodResolver(bannerSchema),
    defaultValues: {
      isActivated: true,
      image: '',
    },
  });

  const { data: bannerDetail } = useQuery<BannerDetailResponse, ApiError>({
    queryKey: ['bannerDetail', bannerId],
    queryFn: () => fetchBannerDetail(bannerId),
    enabled: !!bannerId,
    staleTime: 0,
  });

  const handleClick = () => {
    if (setBannerId) {
      setBannerId(null);
    }
    reset();
    setModals('bannerDetailModal');
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      const file = files[0];
      const result = imageValidation(file);
      if (result) {
        setOneBtnModal(t('message.alert'), result);
        return;
      }

      const url = URL.createObjectURL(file);
      setPreview(url);

      const formData = await saveFile(e, 'single');

      fileMutate(formData, {
        onSuccess(data, variables, context) {
          setValue('image', data.payload.url);
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
          setPreview(null);
        },
      });
    }
  };

  const { mutate: createMutate, isPending } = useMutation<BannerForm, ApiError, ApiProps>({ mutationFn: api });
  const createBanner = (data: z.infer<typeof bannerSchema>) => {
    const restaurantId = getCookie('restaurantId');
    if (restaurantId) {
      createMutate(
        {
          method: bannerId ? 'PUT' : 'POST',
          url: bannerId ? `/admin/banners/${bannerId}` : `/admin/restaurants/${restaurantId}/banners`,
          data: {
            image: data.image,
            link: data.link ? data.link : undefined,
            isActivated: data.isActivated,
            restaurantId: restaurantId,
          },
        },
        {
          onSuccess(data, variables, context) {
            setOneBtnModal(
              t('message.alert'),
              bannerId ? t('banner.배너를성공적으로수정하였습니다') : t('banner.배너를성공적으로생성하였습니다'),
              () => {
                setModals('bannerDetailModal');
                queryClient.invalidateQueries({ queryKey: ['bannerList'] });
              }
            );
            queryClient.invalidateQueries({ queryKey: ['bannerList'] });
          },
          onError(error, variables, context) {
            errorHandler(t, error, setOneBtnModal);
          },
        }
      );
    }
  };

  const handleError = () => {
    setOneBtnModal(t('message.alert'), t('validation.imageRequired'));
  };

  useEffect(() => {
    if (!bannerDetail) {
      return;
    }

    reset({
      image: bannerDetail?.payload.image,
      link: bannerDetail?.payload.link,
      isActivated: bannerDetail?.payload.isActivated,
    });
  }, [bannerDetail]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const formData = watch();
  const image = preview || formData.image || '';

  return (
    <div className='modal-bg' ref={bgRef} onClick={handleClick}>
      <form
        onSubmit={handleSubmit(createBanner, handleError)}
        className='relative shadow-xl md:rounded-lg bg-white py-4 px-4 border border-gray-100 w-screen h-[100dvh] md:h-auto md:max-w-[600px] md:w-full'
        onClick={(e) => e.stopPropagation()}
      >
        <h1 className='font-bold text-[24px]'>{bannerId ? t('banner.배너수정') : t('banner.배너생성')}</h1>
        <div className='py-4 px-4'>
          <div>
            <div className='mt-8'>
              <h2 className={`font-semibold text-[14px] after:content-['*'] after:text-red-500`}>
                {t('banner.배너이미지')}
              </h2>

              <label
                className='mt-4 flex justify-center items-center border border-gray1 rounded-lg w-full aspect-video text-gray2 font-medium cursor-pointer bg-cover bg-center'
                style={{
                  backgroundImage: `url(${image})`,
                }}
              >
                {!image ? (
                  <span className='text-gray2 text-[12px] md:text-[14px]'>{t('banner.배너이미지업로드')}</span>
                ) : (
                  ''
                )}
                <input type='file' accept='images/*' hidden onChange={onChange} />
              </label>
            </div>

            <div className='mt-8'>
              <h2 className='font-semibold text-[14px]'>{t('banner.배너링크')}</h2>
              <CustomInput
                placeholder='https://www.naver.com'
                {...register('link')}
                required={false}
                type='text'
                style='mt-4'
              />
            </div>

            <div className='mt-8'>
              <h2 className='font-semibold text-[14px]'>{t('banner.배너활성화')}</h2>
              <div className='flex justify-start items-center mt-4'>
                <Toggle isOn={formData.isActivated} setOn={() => setValue('isActivated', !formData.isActivated)} />
              </div>
            </div>

            <div className='flex justify-center items-center mt-10 gap-4'>
              <CustomButton
                style={isPending ? 'bg-gray2 text-white' : 'bg-main3 text-white'}
                text={isPending ? t('banner.생성중') : bannerId ? t('banner.수정') : t('banner.생성')}
                type='submit'
                disabled={isPending}
              />
              <CustomButton style='bg-gray2 text-white' text={t('banner.닫기')} type='button' onClick={handleClick} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
