import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError } from 'api';
import { Category, createCategoryApi, updateCategoryApi } from 'api/admin/cateogry';
import CustomButton from 'components/style/Button';
import CustomInput from 'components/style/Input';
import { errorHandler } from 'lib/error';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useModalStore from 'store/useModalStore';

type Props = {
  selectedCategory: Category;
  setSelectedCategory: Dispatch<SetStateAction<Category>>;
};

export default function CreateCategoryModal({ selectedCategory, setSelectedCategory }: Props) {
  const bgRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setModals, setOneBtnModal } = useModalStore();

  const [name, setName] = useState('');

  const handleClick = () => setModals('createCategoryModal');

  const createCategory = useMutation<any, ApiError, { menuId: string; name: string }>({
    mutationFn: ({ menuId, name }) => createCategoryApi(menuId, name),
    onSuccess(data, variables, context) {
      setModals('createCategoryModal');
      setOneBtnModal(t('message.alert'), t('menu.카테고리가생성되었습니다'), () => {
        queryClient.invalidateQueries({ queryKey: ['categories'] });
      });
    },
    onError(error, variables, context) {
      errorHandler(t, error, setOneBtnModal);
    },
  });

  const updateCategory = useMutation<any, ApiError, { categoryId: string; name: string }>({
    mutationFn: ({ categoryId, name }) => updateCategoryApi(categoryId, name),
    onSuccess(data, variables, context) {
      setModals('createCategoryModal');
      setOneBtnModal(t('message.alert'), t('menu.카테고리가수정되었습니다'), () => {
        queryClient.invalidateQueries({ queryKey: ['categories'] });
      });
    },
    onError(error, variables, context) {
      errorHandler(t, error, setOneBtnModal);
    },
    onSettled(data, error, variables, context) {
      setSelectedCategory(null);
    },
  });

  useEffect(() => {
    if (selectedCategory) {
      setName(selectedCategory.name);
    }
  }, [selectedCategory]);

  return (
    <div className='modal-bg' ref={bgRef} onClick={handleClick}>
      <div
        className='relative shadow-xl rounded-2xl w-[360px] bg-white py-4 px-4 border border-gray-100'
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className='text-[16px] font-semibold'>
          {selectedCategory ? t('menu.카테고리수정') : t('menu.카테고리생성')}
        </h2>
        <div className='flex flex-col gap-2 mt-8'>
          <label className='text-[14px] font-medium text-nowrap'>{t('menu.이름')}</label>
          <CustomInput
            placeholder={t('menu.카테고리placeholder')}
            required
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className='flex items-center justify-between gap-9 mt-12'>
          <CustomButton
            text={selectedCategory ? t('menu.수정') : t('menu.생성')}
            onClick={() =>
              selectedCategory
                ? updateCategory.mutate({ categoryId: selectedCategory.id, name })
                : createCategory.mutate({ menuId: params.id, name })
            }
            style='bg-main3 text-white'
          />
          <CustomButton text={t('menu.취소')} onClick={handleClick} style='bg-gray2 text-white' />
        </div>
      </div>
    </div>
  );
}
