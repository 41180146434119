import PcBanner from 'assets/images/landing-pc-banner.png';
import MbBanner from 'assets/images/landing-mb-banner.png';
import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { ReactComponent as LanguageIcon } from 'assets/svg/global.svg';
import { ReactComponent as Icon1 } from 'assets/svg/landing/icon1.svg';
import { ReactComponent as Icon2 } from 'assets/svg/landing/icon2.svg';
import { ReactComponent as Icon3 } from 'assets/svg/landing/icon3.svg';
import { ReactComponent as Icon4 } from 'assets/svg/landing/icon4.svg';
import { ReactComponent as Icon5 } from 'assets/svg/landing/icon5.svg';
import { ReactComponent as Icon6 } from 'assets/svg/landing/icon6.svg';
import { ReactComponent as Icon7 } from 'assets/svg/landing/icon7.svg';
import { ReactComponent as Icon7Mb } from 'assets/svg/landing/icon7-mb.svg';
import { ReactComponent as Icon8 } from 'assets/svg/landing/icon8.svg';
import { ReactComponent as Icon9 } from 'assets/svg/landing/icon9.svg';
import { ReactComponent as Icon10 } from 'assets/svg/landing/icon10.svg';
import { ReactComponent as Icon11 } from 'assets/svg/landing/icon11.svg';
import { ReactComponent as Arrow } from 'assets/svg/landing/right-arrow.svg';
import { ReactComponent as RightArrow } from 'assets/svg/landing/landing-right-arrow.svg';
import { ReactComponent as ButtonRightArrow } from 'assets/svg/landing/button-right-arrow.svg';
import { ReactComponent as NaverIcon } from 'assets/svg/landing/naver-icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/landing/instagram-icon.svg';
import { ReactComponent as InstagramIcon2 } from 'assets/svg/landing/instagram-icon2.svg';
import { ReactComponent as KakaoIcon } from 'assets/svg/landing/kakao-icon.svg';
import { ReactComponent as HamburgerIcon } from 'assets/svg/landing/hamburger-icon.svg';
import { Link } from 'react-router';
import { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PrivacyModal from 'components/modal/PrivacyModal';
import TermsModal from 'components/modal/TermsModal';

gsap.registerPlugin(ScrollTrigger);

type Props = {};

const serviceData = [
  {
    text: `AI 번역 기술로\n10개 이상의 언어 지원`,
    icon: Icon1,
  },
  {
    text: `알레르기 정보\n자동 필터링 기능`,
    icon: Icon2,
  },
  {
    text: `QR 코드 스캔으로\n메뉴 번역 및 정보 제공`,
    icon: Icon3,
  },
];

const functionData = [
  {
    text: `QR 스캔`,
    icon: Icon8,
  },
  {
    text: `언어 선택`,
    icon: Icon9,
  },
  {
    text: `특수 식단\n필터 적용`,
    icon: Icon10,
  },
  {
    text: `메뉴 확인`,
    icon: Icon11,
  },
];

const filtering = [
  {
    title: 'AI 번역 기능',
    contents: `10 개 이상의 언어를\n지원하여 실시간 번역 제공`,
    mobileContents: `10 개 이상의\n 언어 지원`,
  },
  {
    title: '특수 식단 필터링',
    contents: `비건, 할랄, 알레르기\n정보를  한눈에 확인`,
    mobileContents: `비건, 할랄, 알레르기\n정보를  한눈에 확인`,
  },
  {
    title: 'QR Code 접근성',
    contents: `QR CODE로\n빠르게 접근 가능`,
    mobileContents: `QR CODE로\n빠르게 접근 가능`,
  },
  {
    title: '메뉴 확인',
    contents: `개인화된 메뉴\n제공에 대한 신뢰감`,
    mobileContents: `개인화된 메뉴제공에\n 대한 신뢰감`,
  },
];

export default function Landing({}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTermsModal, setTermsModal] = useState(false);
  const [isPrivacyModal, setPrivacyModal] = useState(false);
  useGSAP(() => {
    // 미디어 쿼리 설정
    const mm = gsap.matchMedia();

    const highlightAnimation = {
      // 기본 애니메이션
      simple: {
        scrollTrigger: {
          trigger: '.landing-highlight',
          start: 'top bottom-=50',
        },
        opacity: 0,
        y: 20,
        duration: 0.8,
        stagger: 0.2,
        ease: 'power2.out',
      },

      // 스케일 효과가 있는 애니메이션
      scale: {
        scrollTrigger: {
          trigger: '.landing-highlight',
          start: 'top bottom-=50',
        },
        opacity: 0,
        scale: 0.8,
        duration: 0.8,
        stagger: 0.2,
        ease: 'back.out(1.7)',
      },

      // 텍스트가 밑줄과 함께 나타나는 효과
      underline: {
        scrollTrigger: {
          trigger: '.landing-highlight',
          start: 'top bottom-=50',
        },
        opacity: 0,
        y: 20,
        backgroundSize: '100% 0%',
        backgroundPosition: '0 100%',
        duration: 0.8,
        stagger: 0.2,
        ease: 'power2.out',
        onComplete: (elements) => {
          gsap.to(elements.targets, {
            backgroundSize: '100% 30%',
            duration: 0.4,
          });
        },
      },
    };

    // 모바일 애니메이션 (768px 미만)
    mm.add('(max-width: 767px)', () => {
      // 모바일 헤더 애니메이션
      gsap.from('.mobile-header', {
        y: -30,
        opacity: 0,
        duration: 0.6,
        ease: 'power2.out',
      });

      // 모바일용 섹션 애니메이션
      gsap.from('.banner-animation', {
        opacity: 0,
        scale: 0.95,
        duration: 1,
        delay: 0.2,
        ease: 'power2.out',
        clearProps: 'all',
      });

      gsap.from('.service-card', {
        scrollTrigger: {
          trigger: '.service-card',
          start: 'top bottom-=50',
        },
        opacity: 0,
        y: 50,
        duration: 0.6,
        stagger: 0.1,
      });

      gsap.from('.function-card', {
        scrollTrigger: {
          trigger: '.function-card',
          start: 'top bottom-=50',
        },
        opacity: 0,
        x: -30,
        duration: 0.6,
        stagger: 0.1,
      });

      gsap.from('.filter-item', {
        scrollTrigger: {
          trigger: '.filter-item',
          start: 'top bottom-=50',
        },
        opacity: 0,
        y: 30,
        duration: 0.6,
        stagger: 0.1,
      });

      gsap.from('.icon7-mobile', {
        scrollTrigger: {
          trigger: '.icon7-mobile',
          start: 'top bottom-=50',
          toggleActions: 'play none none reverse',
        },
        opacity: 0,
        y: 30,
        duration: 0.6,
        ease: 'power2.out',
      });

      gsap.from('.landing-highlight', highlightAnimation.simple);
    });

    // 데스크톱 애니메이션 (768px 이상)
    mm.add('(min-width: 768px)', () => {
      gsap.from('.desktop-header', {
        y: -50,
        opacity: 0,
        duration: 0.8,
        ease: 'power2.out',
      });

      gsap.from('.banner-animation', {
        opacity: 0,
        scale: 0.9,
        duration: 1.2,
        delay: 0.2,
        ease: 'power2.out',
        clearProps: 'all',
      });

      gsap.from('.service-card', {
        scrollTrigger: {
          trigger: '.service-card',
          start: 'top bottom-=100',
        },
        opacity: 0,
        y: 100,
        duration: 0.8,
        stagger: 0.2,
      });

      gsap.from('.function-card', {
        scrollTrigger: {
          trigger: '.function-card',
          start: 'top bottom-=100',
        },
        opacity: 0,
        x: -50,
        duration: 0.8,
        stagger: 0.2,
      });

      gsap.from('.filter-item', {
        scrollTrigger: {
          trigger: '.filter-item',
          start: 'top bottom-=100',
        },
        opacity: 0,
        y: 50,
        duration: 0.8,
        stagger: 0.2,
      });
      gsap.from('.icon7-desktop', {
        scrollTrigger: {
          trigger: '.icon7-desktop',
          start: 'top bottom-=100',
          toggleActions: 'play none none reverse',
        },
        opacity: 0,
        y: 50,
        duration: 0.8,
        ease: 'power2.out',
      });

      gsap.from('.landing-highlight', highlightAnimation.scale);
    });
  }, []);

  useEffect(() => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      if (isMenuOpen) {
        gsap.to('.side-menu', {
          x: 0,
          duration: 0.4,
          ease: 'power2.out',
        });
        gsap.to('.menu-backdrop', {
          opacity: 0.5,
          duration: 0.4,
        });
      } else {
        gsap.to('.side-menu', {
          x: '-100%',
          duration: 0.4,
          ease: 'power2.out',
        });
        gsap.to('.menu-backdrop', {
          opacity: 0,
          duration: 0.4,
        });
      }
    }
  }, [isMenuOpen]);
  return (
    <div>
      {isTermsModal && <TermsModal setTermsModal={setTermsModal} />}
      {isPrivacyModal && <PrivacyModal setPrivacyModal={setPrivacyModal} />}
      {/* pc - header */}
      <div className='desktop-header justify-between items-center h-[120px] px-8 hidden md:flex'>
        <LogoIcon width={180} height={180} />
        <div className='flex items-center'>
          <LanguageIcon width={52} height={52} />
          <Link
            to={'admin/login'}
            className='flex items-center justify-center w-[128px] h-[59px] ml-[41px] mr-[24px] border-2 border-main7 rounded-[9px] text-[21px] font-semibold text-main7'
          >
            Sign in
          </Link>
          <Link
            to={'admin/signUp'}
            className='flex items-center justify-center w-[128px] h-[59px] gradient-button rounded-[9px] text-[21px] font-semibold text-white'
          >
            Sign up
          </Link>
        </div>
      </div>

      {/* mobile - header */}
      <div className='mobile-header justify-between items-center w-full px-5 flex md:hidden py-[12px]'>
        <div className='flex items-center gap-[5px]'>
          <button onClick={() => setIsMenuOpen(true)}>
            <HamburgerIcon />
          </button>
          <LogoIcon width={50} height={10} />
        </div>
        <div className='flex items-center gap-2'>
          {/* TODO: 언어 선택 기능 추가 */}
          <span className='text-[10px] font-bold text-main7'>KOREA</span>
          <LanguageIcon width={16} height={16} />
        </div>
      </div>

      {/* mobile - side menu */}
      <div
        className={`fixed top-0 left-0 h-full w-full z-50 transition-all duration-300 ${
          isMenuOpen ? 'visible' : 'invisible'
        }`}
      >
        {/* Backdrop */}
        <div
          className={`menu-backdrop absolute top-0 left-0 w-full h-full bg-black transition-opacity duration-300 ${
            isMenuOpen ? 'opacity-50' : 'opacity-0'
          }`}
          onClick={() => setIsMenuOpen(false)}
        />

        {/* Side Menu Content */}
        <div
          className={`side-menu absolute top-0 left-0 w-full h-full bg-white shadow-lg transition-transform duration-300 ${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <div className='p-5'>
            <div className='flex justify-between items-center mb-[120px]'>
              <LogoIcon width={60} height={10} />
              <button onClick={() => setIsMenuOpen(false)}>
                <XMarkIcon className='w-6 h-6' />
              </button>
            </div>

            <nav className='flex flex-col gap-4'>
              <Link
                to='/admin/login'
                className='flex items-center justify-center w-full h-[52px] border-2 border-main7 rounded-[9px] text-[21px] font-semibold text-main7'
              >
                Login
              </Link>
              <Link
                to='/admin/signUp'
                className='flex items-center justify-center w-full h-[52px] gradient-button rounded-[9px] text-[21px] font-semibold text-white'
              >
                Sign up
              </Link>
            </nav>
          </div>
        </div>
      </div>

      {/* section 1 */}
      <section className='banner-animation'>
        <img src={PcBanner} alt='banner' className='hidden md:block' />
        <img src={MbBanner} alt='banner' className='block md:hidden' />
        <div className='px-5 w-full md:hidden'>
          <button
            className='flex items-center justify-center relative rounded-[15px] bg-black text-[12px] font-bold font-NEXONLv1GothicOTF w-full text-white h-[40px]'
            onClick={() => alert('추가 예정')}
          >
            메뉴판 보러가기
            <span className='absolute right-[18px]'>
              <ButtonRightArrow />
            </span>
          </button>
        </div>
      </section>

      {/* section 2 */}
      <section
        className='w-full flex flex-col items-center justify-center pt-[50px] md:pt-[163px] pb-[50px] md:pb-[130px] font-NEXONFootballGothicBA1 overflow-hidden'
        style={{
          background: 'linear-gradient(to bottom,rgb(251, 251, 255),rgb(224, 222, 254),rgb(251, 251, 255))',
        }}
      >
        <span className='landing-highlight landing-big-text text-center'>외국인을 위한 스마트한 메뉴 서비스</span>
        <div className='max-w-[1200px] w-full mt-[48px] md:mt-[98px] relative flex items-center justify-between md:justify-center gap-[12px] sm:gap-[20px] md:gap-[35px] px-5'>
          {serviceData.map((item, index) => (
            <div key={index} className='service-card flex flex-col items-center justify-center w-full'>
              <div
                key={index}
                className='flex flex-col items-center justify-center w-full  aspect-square shadow-2xl bg-white rounded-[20px] p-4 '
              >
                <item.icon className='max-w-[183px] max-h-[183px] min-w-[70px] min-h-[70px] w-full h-full' />
                <span className='mt-[20px] md:text-[20px] lg:text-[24px] leading-[21px] md:leading-[35px] font-bold whitespace-pre-line text-center hidden md:inline'>
                  {item.text}
                </span>
              </div>
              <span className='mt-[20px] text-[10px] sm:text-[14px] leading-[18px] sm:leading-[24px] font-bold whitespace-pre-line text-center md:hidden'>
                {item.text}
              </span>
            </div>
          ))}
          <Icon5 className='absolute -bottom-[73px] -right-[236px] hidden md:block' />
          <Icon4 className='absolute -bottom-[296px] -right-[84px] hidden md:block' />
          <Icon6 className='absolute -bottom-[230px] right-[210px] hidden md:block' />
        </div>

        <div className='pt-[92px] md:pt-[485px]'>
          <div className='md:leading-[64px] leading-[32px] text-center'>
            <span className='landing-highlight landing-big-text'>{`외국인 중 70% 이상이 메뉴를`}</span>
          </div>
          <div className='md:leading-[64px] leading-[32px] text-center'>
            <span className='landing-highlight landing-big-text'>{`이해하지 못해 불편함을 겪고 있습니다.`}</span>
          </div>
        </div>

        <div className='mt-[40px] md:mt-[100px] md:px-5 w-screen'>
          <Icon7 className='icon7-desktop w-full hidden sm:block' />
          <Icon7Mb className='icon7-mobile w-full block sm:hidden' />
        </div>
      </section>

      {/* section 3 */}
      <section
        className='lg:h-[1700px] w-full pt-[45px] lg::pt-[163px] lg:pb-[130px] font-NEXONFootballGothicBA1'
        style={{
          background:
            'linear-gradient(to bottom, rgba(0, 228, 166, 0.2) 0%, rgba(43, 233, 181, 0.2) 17%, #FFFFFF 100%)',
        }}
      >
        <div className='max-w-[1200px] w-full m-auto flex flex-col items-center justify-center px-5 lg:px-0'>
          <span className='landing-highlight landing-big-text text-center'>
            더 이상 메뉴판 앞에서 망설이게 둘 건가요?
          </span>

          <span className='landing-big-text whitespace-pre-wrap text-center mt-[45px] md:mt-[113px] lg:mt-[113px]'>
            {`별도의 앱 설치 없이\n`}
            <span className='leading-[21px] bg-yellow2 rounded-full text-main8'>{`웹 기반으로`}</span>
            {` 빠르게 접근 가능`}
          </span>

          <div className='flex justify-center mt-[93px] w-full gap-[26px] lg:gap-0'>
            {functionData.map((item, index) => (
              <div key={index} className='function-card flex flex-col lg:flex-row items-center w-full'>
                <div className='bg-white overflow-hidden shadow-2xl w-full min-w-0 max-w-[220px] aspect-[11/12] rounded-[20px] flex flex-col items-center justify-center whitespace-pre-wrap p-[12px]'>
                  <item.icon className='w-full h-full max-w-[100px] max-h-[100px]' />
                  <span className='hidden md:block text-center mt-3 font-extrabold text-lg '>{item.text}</span>
                </div>
                <span className=' md:hidden text-center mt-3 whitespace-pre-wrap'>{item.text}</span>
                <span
                  className={`px-[27px] hidden lg:${functionData.length - 1 === index ? 'block invisible' : 'block'}`}
                >
                  <RightArrow />
                </span>
              </div>
            ))}
          </div>

          <span className='landing-big-text whitespace-pre-wrap text-center mt-[60px] md:mt-[120px] lg:mt-[188px]'>
            {`간편하게 QR 찍고 \n`}
            <span className='leading-[21px] bg-yellow2 rounded-full text-main8'>필터링 메뉴판을</span>
            {` 사용해보세요!`}
          </span>

          <div className='relative flex items-center justify-center gap-[10px] md:gap-[40px] lg:gap-[73px] mt-[68px] md:mt-[109px]'>
            <div className='border-t-4 border-gray6 border-dashed absolute top-[45%] md:top-[53%] w-[80%]' />

            {filtering.map((item, index) => (
              <div key={index} className='filter-item flex flex-col justify-center items-center'>
                <div className='circle'>
                  <div className='triangle'></div>
                  <span className='number'>{index + 1}</span>
                </div>
                <span className='text-xs md:text-base lg:text-lg font-bold mt-[39px] whitespace-nowrap'>
                  {item.title}
                </span>
                <span
                  className={`w-4 h-4 md:w-6 md:h-6 rounded-full mt-[37px] z-10 ${
                    index === 0 || index === 3 ? 'bg-main8' : 'bg-gray6'
                  }`}
                />

                <div className='mt-[24px] w-full max-w-[240px] h-[142px] px-[14px] lg:px-[20px] py-[37px] border-t-2 border-main8 bg-[rgb(243,254,251)]'>
                  <span className='hidden lg:block text-base font-bold whitespace-pre-wrap text-center'>
                    {item.contents}
                  </span>
                  <span className='block lg:hidden text-xs font-bold whitespace-normal text-center'>
                    {item.mobileContents}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <Link
            to={'/admin/signUp'}
            className='flex items-center justify-center relative mt-[67px]  py-[10px] lg:py-[20px] w-full lg:w-[300px] font-semibold text-[16px] lg:text-[22px] bg-main6 rounded-[50px] text-white'
          >
            가입하기
            <Arrow className='absolute right-[23px] hidden lg:block' />
          </Link>
        </div>
      </section>

      {/* mobile - footer */}
      <section className='block lg:hidden border-t border-gray6 mt-[60px] pt-[60px] pb-[60px] pl-5'>
        <span className='text-lg leading-3 font-bold landing-highlight font-NEXONFootballGothicBA1'>
          글로벌 메뉴판의 새로운 기준, 온뉴온
        </span>
        <p className='text-sm font-bold font-NEXONFootballGothicBA1 mt-10 mb-5'>고객센터 : 1588 - 8932</p>
        <div className='flex items-center gap-[17px] mb-7 mt-7'>
          <Link to={'https://blog.naver.com/onbooth_'} target='_blank'>
            <KakaoIcon />
          </Link>
          <Link to={'https://blog.naver.com/onbooth_'} target='_blank'>
            <NaverIcon />
          </Link>
          <Link to={'https://instagram.com/onnuon_co'} target='_blank'>
            <InstagramIcon />
          </Link>
        </div>
        <div className='flex gap-5 mb-8'>
          <p
            className='text-sm font-NEXONFootballGothicBA1 border-r border-gray6 pr-5'
            onClick={() => setTermsModal(true)}
          >
            이용약관
          </p>
          <p className='text-sm font-NEXONFootballGothicBA1 border-r' onClick={() => setPrivacyModal(true)}>
            개인정보처리방침
          </p>
        </div>

        <p className='text-xs leading-6 text-left'>주식회사 온뉴온 대표 : 박성철</p>
        {/* <p className='text-xs leading-6 text-left'>사업자번호 : 000-00-00000</p> */}
        {/* <p className='text-xs leading-6 text-left'>통신판매업 신고번호 2024-서울삼성-0000</p> */}
        <p className='text-xs leading-6 text-left'>개인정보책임관리자 : 박성철 </p>
        <br />
        <p className='text-xs leading-6 text-left'>이메일 : info@onnuon@com</p>
        <p className='text-xs leading-6 text-left'>주소 : 서울시 중구 을지로16길 5-3 동아빌딩 3층 </p>
        <p className='text-xs leading-6 text-left'>운영시간 평일 10:00 ~ 18:00 </p>
        <p className='text-xs leading-6 text-left'>(점심시간 12:00~ 13:00) 주말, 공휴일 휴무</p>
      </section>
      {/* pc - footer */}
      <section className='hidden lg:block pt-[106px] pb-[230px] mt-[104px] w-[1200px] border-t-2 border-gray6 m-auto'>
        <div className='flex items-center'>
          <div className='mr-[200px]'>
            <div>
              <LogoIcon width={120} height={25} />
            </div>
            <span className='text-[15px] font-normal mt-5 bg-yellow2 leading-[10px] rounded-[20px]'>
              글로벌 메뉴판의 새로운 기준, 온뉴온
            </span>
          </div>
          <div className='flex items-center gap-5'>
            <button className='text-[16px] font-NEXONFootballGothicBA1' onClick={() => setTermsModal(true)}>
              이용약관
            </button>
            <div className='h-2 w-[2px] rounded-2xl bg-gray6' />
            <button className='text-[16px] font-NEXONFootballGothicBA1' onClick={() => setPrivacyModal(true)}>
              개인정보처리방침
            </button>
          </div>

          <div className='ml-[200px] flex items-center gap-[17px]'>
            <Link to={'https://blog.naver.com/onbooth_'} target='_blank'>
              <KakaoIcon />
            </Link>
            <Link to={'https://blog.naver.com/onbooth_'} target='_blank'>
              <NaverIcon />
            </Link>
            <Link to={'https://instagram.com/onnuon_co'} target='_blank'>
              <InstagramIcon2 />
            </Link>
          </div>
        </div>
        <div className='mt-5'>
          <a href='tel:15888932' className='text-[15px] font-NEXONFootballGothicBA1'>
            고객센터 : 1588 - 8932
          </a>
        </div>
        <div>
          <div className='flex gap-[144px] text-[15px] leading-[160%] font-thin whitespace-pre-wrap mt-3'>
            <p>
              {/* {`주식회사 온뉴온 대표 : 박성철\n사업자번호 : 000-00-00000\n통신판매업 신고번호 2024-서울삼성-0000\n개인정보책임관리자 : 박성철`} */}
              {`주식회사 온뉴온 대표 : 박성철\n개인정보책임관리자 : 박성철`}
            </p>
            <p>
              {`이메일 : info@onnuon.com\n주소 : 서울시 중구 을지로16길 5-3 동아빌딩 3층\n운영시간 평일 10:00 ~ 18:00\n(점심시간 12:00~ 13:00) 주말, 공휴일 휴무`}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
