export const terms = `onnuon 웹사이트 이용약관
제1조 (목적)
본 약관은 주식회사 onnuon(이하 "회사"라 함)이 운영하는 웹사이트(이하 "사이트"라 함)에서 제공하는 특수 식단 필터링 서비스 및 관련 제반 서비스의 이용과 관련하여 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
제2조 (정의)
"사이트"란 회사가 특수 식단 필터링 서비스를 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 구성한 가상의 공간을 말합니다.
"이용자"란 사이트에 접속하여 본 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
"회원"이란 사이트에 개인정보를 제공하여 회원등록을 한 자로서, 사이트의 정보를 지속적으로 제공받으며 서비스를 이용할 수 있는 자를 말합니다.
"게시물"이란 회원이 서비스를 이용함에 있어 사이트에 게시한 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
제3조 (약관의 효력 및 변경)
본 약관은 사이트에 게시하여 공지함으로써 효력이 발생합니다.
회사는 관련법령의 변경이나 서비스의 개선을 위하여 본 약관을 변경할 수 있으며, 변경된 약관은 사이트에 공지함으로써 효력이 발생합니다.
이용자는 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며, 변경된 약관의 효력 발생일 이후에도 서비스를 계속 이용할 경우 약관의 변경사항에 동의한 것으로 간주됩니다.
제4조 (서비스의 제공 및 변경)
회사는 다음과 같은 서비스를 제공합니다:
할랄, 코셔, 비건 등 특수 식단 정보 제공
음식점 및 메뉴 필터링 서비스
식재료 정보 제공 서비스
리뷰 작성 및 조회 서비스
기타 회사가 정하는 서비스
회사는 서비스의 품질 향상을 위해 운영상, 기술상의 필요에 따라 서비스를 변경할 수 있습니다.
제5조 (회원가입)
이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.
회사는 다음 각 호에 해당하는 회원가입 신청에 대하여는 승인을 하지 않을 수 있습니다:
실명이 아닌 경우
타인의 명의를 사용한 경우
회원가입 신청서의 내용을 허위로 기재한 경우
기타 회원가입 신청자의 귀책사유로 회원가입 승인이 불가능한 경우
제6조 (개인정보보호)
회사는 관련법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.
개인정보의 보호 및 사용에 대해서는 관련법령 및 회사의 개인정보처리방침이 적용됩니다.
회사는 회원의 개인정보를 본인의 승낙 없이 제3자에게 제공하지 않습니다.
제7조 (회원의 의무)
회원은 다음 행위를 하여서는 안 됩니다:
회원가입 신청 시 허위내용을 등록하는 행위
타인의 정보를 도용하는 행위
사이트에 게시된 정보를 임의로 변경하는 행위
회사가 정한 정보 이외의 정보를 송신하거나 게시하는 행위
회사와 기타 제3자의 저작권 등 지적재산권을 침해하는 행위
회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
외설적 또는 폭력적인 게시물을 게시하는 행위
기타 불법적이거나 부당한 행위
제8조 (게시물의 관리)
회원의 게시물이 관련법령 및 본 약관에 위반되는 내용을 포함하는 경우, 회사는 관련법령이 정한 절차에 따라 해당 게시물에 대해 게시중단 및 삭제 등의 조치를 취할 수 있습니다.
회사는 회원이 게시하거나 등록하는 사이트의 게시물이 다음 각 호에 해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있습니다:
다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용
공공질서 및 미풍양속에 위반되는 내용
범죄적 행위에 결부된다고 인정되는 내용
회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용
허위의 식당 정보나 리뷰를 게시하는 행위
제9조 (회사의 면책조항)
회사는 다음과 같은 사항에 대해 책임을 지지 않습니다:
음식점에서 제공하는 식재료 정보의 정확성
게시물에 대한 신뢰성 및 정확성
이용자 간의 상호거래에서 발생하는 문제
회원이 게시한 정보, 자료, 사실의 신뢰도와 정확성
서비스 이용 중 발생하는 네트워크 문제
전시, 사변, 천재지변, 기타 불가항력적 사유로 인한 서비스 제공 중단
제10조 (저작권의 귀속 및 이용제한)
회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 회사에 귀속됩니다.
이용자는 사이트를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
제11조 (분쟁해결)
서비스 이용과 관련하여 회사와 회원 간에 분쟁이 발생한 경우, 양 당사자는 분쟁의 해결을 위해 성실히 협의합니다.
본 약관과 관련된 소송의 관할법원은 회사의 본사 소재지를 관할하는 법원으로 합니다.
제12조 (기타)
본 약관에 명시되지 않은 사항은 관련법령의 규정과 일반적인 상관례에 따릅니다.
본 약관은 한국어를 정본으로 합니다. 다른 언어로 번역된 약관과 한국어 약관 사이에 불일치가 있는 경우 한국어 약관이 우선합니다.
부칙 본 약관은 2025년 1월 21일부터 시행합니다.`;
